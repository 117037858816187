import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const blog: SvgIconMap = {
	size: {
		width: 512,
		height: 512,
	},
	Icon: ({ fill = defaultFill }) => (
		<g
			transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
			fill={fill}
			stroke="none">
			<path
				d="M4379 4815 c-82 -20 -156 -56 -224 -108 -103 -78 -1593 -1580 -1668
			-1681 -194 -262 -304 -577 -309 -887 -1 -94 1 -110 22 -147 13 -23 42 -54 64
			-69 39 -27 42 -28 180 -27 326 2 652 125 926 350 36 29 421 410 856 846 766
			768 792 795 828 869 66 138 82 275 46 413 -52 202 -190 356 -381 423 -91 32
			-251 41 -340 18z m229 -404 c98 -50 138 -160 93 -256 -26 -55 -1581 -1606
			-1670 -1665 -116 -77 -284 -151 -398 -175 -41 -8 -41 -5 -8 109 29 101 98 244
			163 336 55 79 1591 1623 1642 1650 47 25 130 26 178 1z"
			/>
			<path
				d="M872 4279 c-375 -48 -695 -312 -819 -674 -54 -158 -53 -136 -53
			-1510 0 -1225 1 -1275 19 -1347 57 -224 223 -389 444 -444 95 -23 237 -16 332
			18 100 35 160 76 332 231 l151 136 1109 3 1108 3 90 28 c358 111 609 365 702
			713 25 95 27 112 31 409 4 344 2 363 -55 426 -76 85 -206 86 -285 2 -55 -57
			-58 -78 -58 -376 0 -238 -3 -284 -19 -349 -54 -209 -196 -362 -406 -435 -46
			-16 -140 -18 -1200 -23 -1048 -5 -1153 -6 -1180 -22 -17 -9 -112 -89 -210
			-178 -99 -89 -198 -170 -220 -181 -74 -35 -178 -16 -232 42 -56 61 -53 -8 -53
			1346 0 1367 -2 1319 60 1447 74 155 199 262 381 322 50 17 122 19 980 24 l925
			5 42 28 c69 45 104 137 82 218 -15 56 -80 123 -135 138 -47 13 -1762 13 -1863
			0z"
			/>
			<path
				d="M936 3076 c-109 -40 -165 -168 -117 -271 22 -48 54 -79 103 -100 55
			-22 701 -22 756 0 156 66 162 279 9 362 -28 16 -69 18 -372 20 -271 2 -348 0
			-379 -11z"
			/>
			<path
				d="M936 2276 c-109 -40 -165 -168 -117 -271 22 -48 54 -79 103 -100 55
			-22 701 -22 756 0 156 66 162 279 9 362 -28 16 -69 18 -372 20 -271 2 -348 0
			-379 -11z"
			/>
		</g>
	),
}
