import idx from 'idx'
import React from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'

import { Grid } from '@app/components/grid'
import { IconNavigation } from '@app/components/icon-navigation'
import { InView } from '@app/components/in-view'
import { Navigation } from '@app/components/navigation'
import { withContentStore, WithContentStoreProps } from '@app/stores'
import { CompassUtils } from '@app/utils'
import { ResourceTemplateType } from '@compass/types'
import { mixins } from '@styleguide'

import { Logo } from '../logo'
import { SearchModal } from '../modals'
import * as HeaderComponents from './header-components'

export interface HeaderState {
	isMobileOpen: boolean
}

const IconColumnWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',

	...mixins.mediaStyles({
		lg: {
			justifyContent: 'flex-end',
		},
	}),
})

const SearchWrapper = styled.div({})

class HeaderComponent extends React.Component<WithContentStoreProps, HeaderState> {
	public state: HeaderState = {
		isMobileOpen: false,
	}

	public setOpen: (state: boolean) => void

	private toggleNavigation = () => {
		this.setState(
			{
				isMobileOpen: !this.state.isMobileOpen,
			},
			() => {
				const operation = this.state.isMobileOpen ? 'add' : 'remove'
				document.body.classList[operation]('overflow-hidden')
			},
		)
	}

	private getCurrentResource() {
		const { contentStore } = this.props
		return idx(contentStore, _ => _.state.content[contentStore.state.pathname])
	}

	public render() {
		const resource = this.getCurrentResource()
		const { pathname } = this.props.contentStore.state

		const { isMobileOpen } = this.state
		const isHidden = !!(resource && resource.slug === '/')

		// @ts-ignore
		const template = resource && CompassUtils.resource.getResourceTemplateType(resource)
		const isFullScreenPage =
			template === ResourceTemplateType.MAP_PAGE ||
			template === ResourceTemplateType.MEDIA_PAGE ||
			template === ResourceTemplateType.LIST_PAGE ||
			template === ResourceTemplateType.EVENTS_PAGE ||
			template === ResourceTemplateType.EVENT_PAGE ||
			template === ResourceTemplateType.HOME_PAGE

		const isHome = pathname === '/'

		return (
			<InView>
				{({ scrollPosition }) => (
					<HeaderComponents.Wrapper isHidden={isHidden} isScrolling={scrollPosition > 0}>
						<HeaderComponents.ScrollWrapper isOpen={isMobileOpen}>
							<Grid.Container>
								<Grid.Row>
									<HeaderComponents.LogoColumn xs={16} md={3} lg={4} xl={3}>
										<Logo isSmall={scrollPosition > 0 || isFullScreenPage} />
										<HeaderComponents.Toggle
											isOpen={isMobileOpen}
											onClick={this.toggleNavigation}
										/>
									</HeaderComponents.LogoColumn>
									<HeaderComponents.NavigationColumn
										isHome={isHome}
										isOpen={isMobileOpen}
										xs={16}
										md={13}
										lg={12}
										xl={13}>
										<HeaderComponents.NavigationRow>
											<Grid.Column xs={16} lg={12}>
												<Navigation onClick={this.toggleNavigation} />
											</Grid.Column>
											<Grid.Column
												xs={16}
												md={4}
												css={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
												}}>
												<SearchWrapper>
													<SearchModal
														setOpen={setOpen =>
															(this.setOpen = setOpen!)
														}
													/>
												</SearchWrapper>
												<IconColumnWrapper>
													<IconNavigation
														onClick={this.toggleNavigation}
														onSearch={() => this.setOpen(true)}
													/>
												</IconColumnWrapper>
											</Grid.Column>
										</HeaderComponents.NavigationRow>
									</HeaderComponents.NavigationColumn>
								</Grid.Row>
							</Grid.Container>
						</HeaderComponents.ScrollWrapper>
					</HeaderComponents.Wrapper>
				)}
			</InView>
		)
	}
}

export const Header = compose(withContentStore)(HeaderComponent)
