import React from 'react'
import { compose } from 'recompose'

import { SvgIcon } from '@app/components/svg-icons'
import { shellConfig } from '@app/config'
import {
	withModalStore,
	WithModalStoreProps,
	withSearchStore,
	WithSearchStoreProps,
} from '@app/stores'
import { colors } from '@styleguide'

import { Tooltip, TooltipInner } from '../tooltip'
import * as IconNavigationComponents from './icon-navigation-components'

interface IconNavigationBaseProps {
	onClick?: () => void
	onSearch?: () => void
	withSearch?: boolean
}

type IconNavigationProps = IconNavigationBaseProps & WithModalStoreProps & WithSearchStoreProps

export const IconNavigationComponent: React.SFC<IconNavigationProps> = ({
	searchStore,
	modalStore,
	onClick,
	onSearch,
	withSearch = true,
	...rest
}) => (
	<IconNavigationComponents.Wrapper {...rest}>
		{shellConfig.socials
			.slice()
			.reverse()
			.map(social => {
				const hoverBackground = social.type.toUpperCase() as keyof typeof colors
				const defaultBackground = (social.type.toUpperCase() +
					'_LIGHT') as keyof typeof colors
				return (
					<Tooltip
						key={social.url}
						position="bottom"
						sticky
						stickyDuration={false}
						html={<TooltipInner>{social.type}</TooltipInner>}>
						<IconNavigationComponents.Link
							href={social.url}
							target="_blank"
							rel="noopener noreferrer"
							backgroundColors={{
								default: defaultBackground,
								hover: hoverBackground,
							}}>
							<SvgIcon icon={social.type} fill={colors.WHITE} />
						</IconNavigationComponents.Link>
					</Tooltip>
				)
			})}
		<Tooltip
			position="bottom"
			sticky
			stickyDuration={false}
			html={<TooltipInner>Contact us</TooltipInner>}>
			<IconNavigationComponents.Link
				backgroundColors={{
					default: 'INFO_LIGHT',
					hover: 'INFO',
				}}
				onClick={() => modalStore.openModal('textSelection')}>
				<SvgIcon icon="contact" fill={colors.WHITE} />
			</IconNavigationComponents.Link>
		</Tooltip>
		{withSearch && (
			<Tooltip
				position="bottom"
				sticky
				stickyDuration={false}
				html={<TooltipInner>Search</TooltipInner>}>
				<IconNavigationComponents.Link
					backgroundColors={{
						default: 'INFO_LIGHT',
						hover: 'INFO',
					}}
					onClick={() => onSearch && onSearch()}>
					<SvgIcon icon="search" fill={colors.WHITE} />
				</IconNavigationComponents.Link>
			</Tooltip>
		)}
		<Tooltip
			position="bottom"
			sticky
			stickyDuration={false}
			html={<TooltipInner>Blog</TooltipInner>}>
			<IconNavigationComponents.RouterLink
				onClick={onClick}
				backgroundColors={{
					default: 'INFO_LIGHT',
					hover: 'INFO',
				}}
				to="/info/blog/">
				<SvgIcon icon="blog" fill={colors.WHITE} />
			</IconNavigationComponents.RouterLink>
		</Tooltip>
		<Tooltip
			position="bottom"
			sticky
			stickyDuration={false}
			html={<TooltipInner>About us</TooltipInner>}>
			<IconNavigationComponents.RouterLink
				onClick={onClick}
				backgroundColors={{
					default: 'INFO_LIGHT',
					hover: 'INFO',
				}}
				to="/info/">
				<SvgIcon icon="info" fill={colors.WHITE} />
			</IconNavigationComponents.RouterLink>
		</Tooltip>
	</IconNavigationComponents.Wrapper>
)

export const IconNavigation = compose<IconNavigationProps, IconNavigationBaseProps>(
	withModalStore,
	withSearchStore,
)(IconNavigationComponent)
