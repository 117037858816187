import styled from 'styled-components'

import { mixins, spacing, transition } from '@styleguide'

export interface LogoIconProps {
	color: string
	isActive: boolean
	isLarge: boolean
}

export const LogoIcon = styled.div<LogoIconProps>(({ color, isActive }) => ({
	position: 'relative',
	width: 32,
	height: 32,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',

	' svg': {
		position: 'relative',
		maxWidth: '100%',
		maxHeight: '100%',
	},

	[mixins.media.max('sm')]: {
		marginRight: spacing.sm,

		[' [fill]:not([fill="none"])']: {
			fill: 'white',
		},
		[' [stroke]:not([stroke="none"])']: {
			stroke: 'white',
		},
	},

	...mixins.mediaStyles({
		sm: {
			position: 'absolute',
			width: '5vh',
			height: '5vh',

			'&::before': {
				position: 'absolute',
				content: '""',
				top: '50%',
				left: '50%',
				width: '150%',
				height: '150%',
				transform: 'translate(-50%, -50%)',
				background: color,
				borderRadius: '50%',
				opacity: isActive ? 1 : 0,
				transition: transition.config,
				transitionProperty: 'opacity',
			},

			[' [fill]:not([fill="none"])']: {
				transition: transition.config,
				transitionProperty: 'fill',
				...(isActive && { fill: 'white' }),
			},
			[' [stroke]:not([stroke="none"])']: {
				transition: transition.config,
				transitionProperty: 'stroke',
				...(isActive && { stroke: 'white' }),
			},
		},
	}),
}))

export const finances = styled(LogoIcon)(({ isLarge }) =>
	mixins.mediaStyles({
		sm: {
			top: '50%',
			left: '50%',
			marginTop: -380,
			transform: 'translate(-50%, -50%)',
		},
		lg: {
			marginTop: isLarge ? '-40vh' : '-32vh',
		},
	}),
)

export const health = styled(LogoIcon)(({ isLarge }) =>
	mixins.mediaStyles({
		sm: {
			top: '50%',
			left: '50%',
			marginTop: 380,
			transform: 'translate(-50%, -50%)',
		},
		lg: {
			marginTop: isLarge ? '40vh' : '32vh',
		},
	}),
)

export const housing = styled(LogoIcon)(({ isLarge }) =>
	mixins.mediaStyles({
		sm: {
			top: '50%',
			left: '50%',
			marginLeft: 380,
			transform: 'translate(-50%, -50%)',
		},
		lg: {
			marginLeft: isLarge ? '40vh' : '32vh',
		},
	}),
)

export const transport = styled(LogoIcon)(({ isLarge }) =>
	mixins.mediaStyles({
		sm: {
			top: '50%',
			left: '50%',
			marginLeft: -380,
			transform: 'translate(-50%, -50%)',
		},
		lg: {
			marginLeft: isLarge ? '-40vh' : '-32vh',
		},
	}),
)

export const sports = styled(LogoIcon)(({ isLarge }) =>
	mixins.mediaStyles({
		sm: {
			top: '50%',
			left: '50%',
			marginLeft: -200,
			marginTop: 200,
			transform: 'translate(-50%, -50%)',
		},
		lg: {
			marginLeft: isLarge ? '-21vh' : '-17vh',
			marginTop: isLarge ? '21vh' : '17vh',
		},
	}),
)

export const community = styled(LogoIcon)(({ isLarge }) =>
	mixins.mediaStyles({
		sm: {
			top: '50%',
			left: '50%',
			marginLeft: 200,
			marginTop: -200,
			transform: 'translate(-50%, -50%)',
		},
		lg: {
			marginLeft: isLarge ? '21vh' : '17vh',
			marginTop: isLarge ? '-21vh' : '-17vh',
		},
	}),
)

export const cityMap = styled(LogoIcon)(({ isLarge }) =>
	mixins.mediaStyles({
		sm: {
			top: '50%',
			left: '50%',
			marginLeft: -200,
			marginTop: -200,
			transform: 'translate(-50%, -50%)',
		},
		lg: {
			marginLeft: isLarge ? '-21vh' : '-17vh',
			marginTop: isLarge ? '-21vh' : '-17vh',
		},
	}),
)

export const events = styled(LogoIcon)(({ isLarge }) =>
	mixins.mediaStyles({
		sm: {
			top: '50%',
			left: '50%',
			marginLeft: 200,
			marginTop: 200,
			transform: 'translate(-50%, -50%)',
		},
		lg: {
			marginLeft: isLarge ? '21vh' : '17vh',
			marginTop: isLarge ? '21vh' : '17vh',
		},
	}),
)
